import Box from "@/design-system/Box";
import UnstyledButton from "@/design-system/Button/UnstyledButton";
import FlexContainer from "@/design-system/FlexContainer";
import Icon from "@/design-system/Icon";
import Typography from "@/design-system/Typography";
import { theme } from "@/theme";
import dayjs from "dayjs";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import ExportedImage from "next-image-export-optimizer";
import Link from "next/link";
import { Key, useState } from "react";
import styled from "styled-components";

const AuthoImage = styled(ExportedImage)`
  border-radius: 50%;
  overflow: hidden;
  margin-right: ${theme.spacing(2)};
`;

interface SliderPropTypes {
  url: string;
  name: string;
  isHubspot: boolean;
  img: string;
  categories__name: string;
  subCategories__name: string;
  author__img: string;
  author__firstName: string;
  author__lastName: string;
  createdAt: string;
  readtime: string;
}

const EditorPickSlider = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      loop: true,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider?.next();
          }, 2000);
        }
        slider?.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider?.on("dragStarted", clearNextTimeout);
        slider?.on("animationEnded", nextTimeout);
        slider?.on("updated", nextTimeout);
      },
    ]
  );

  const backClick = (e) => {
    return e.stopPropagation() || instanceRef.current?.prev();
  };
  const nextClick = (e) => {
    return e.stopPropagation() || instanceRef.current?.next();
  };

  return (
    <Box height={"100%"}>
      <Box ref={ref} className="keen-slider" height={"100%"}>
        {data.map((val: SliderPropTypes, key: Key | null | undefined) => (
          <div key={key} className="keen-slider__slide">
            <Link
              style={{ width: "100%", height: "100%", display: "block" }}
              href={`/blog/${val.url}`}
              key={val.name}
              target={val?.isHubspot ? "_blank" : "_self"}
            >
              <ExportedImage
                alt={""}
                fill
                objectFit="cover"
                src={`${process.env.NEXT_PUBLIC_CDN}/images/blog/blogHero/${val.img}`}
                unoptimized
              />

              <FlexContainer
                height={"100%"}
                background="rgba(0,0,0,0.5)"
                flexDirection={"column"}
                style={{ color: "#fff" }}
                justifyContent="flex-end"
              >
                <Box pb={theme.spacing(24)} px={theme.spacing(16)}>
                  <Typography
                    color={"inherit"}
                    mb={theme.spacing(6)}
                    className="t-capital"
                    varient="labels"
                    weightVarient="bold"
                  >
                    FEATURED / {val.categories__name}{" "}
                    {val.subCategories__name && "/ " + val.subCategories__name}
                  </Typography>
                  <Typography
                    color={"inherit"}
                    mb={theme.spacing(6)}
                    varient="heading3"
                    weightVarient="semibold"
                  >
                    {val.name}
                  </Typography>
                  <FlexContainer justifyContent={"space-between"}>
                    <FlexContainer alignItems={"center"}>
                      <AuthoImage
                        alt=""
                        width={16}
                        height={16}
                        src={`${process.env.NEXT_PUBLIC_CDN}/images/blog/authors/${val?.author__img}`}
                        unoptimized={true}
                      />
                      <Typography
                        varient="captionl"
                        color={"inherit"}
                        weightVarient="medium"
                      >
                        {val.author__firstName} {val.author__lastName}
                      </Typography>
                    </FlexContainer>
                    <Typography
                      varient="captionl"
                      color={theme.colors.gray[100]}
                    >
                      {dayjs(val.createdAt).format(`DD MMM'YY  `)}
                      {val.readtime}
                    </Typography>
                  </FlexContainer>
                </Box>
              </FlexContainer>
            </Link>
          </div>
        ))}
      </Box>
      <FlexContainer
        px={theme.spacing(16)}
        position={"absolute"}
        bottom={32}
        width="100%"
        mt={theme.spacing(6)}
        minHeight={"40px"}
        justifyContent={"space-between"}
        alignItems="center"
      >
        {loaded && instanceRef?.current && (
          <>
            <Dots currentSlide={currentSlide} instanceRef={instanceRef} />
            <FlexContainer alignItems={"center"}>
              <ArrowBack backClick={backClick} />
              <ArrowNext nextClick={nextClick} />
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    </Box>
  );
};

export default EditorPickSlider;

const ArrowBack = ({ backClick }) => {
  return (
    <UnstyledButton
      mr={theme.spacing(4)}
      display="inline-flex"
      onClick={backClick}
      style={{ transform: "rotate(180deg)" }}
    >
      <Icon type="arrow-right" varient="solid" color={"#fff"} />
    </UnstyledButton>
  );
};
const ArrowNext = ({ nextClick }) => {
  return (
    <UnstyledButton onClick={nextClick} display="inline-flex">
      <Icon type="arrow-right" varient="solid" color={"#fff"} />
    </UnstyledButton>
  );
};

const DotStyle = styled(UnstyledButton)<{ active: boolean }>`
  width: ${({ active }) => (active ? "43px" : "4px")};
  height: 4px;
  background-color: #fff;
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  margin-right: ${theme.spacing(2)};
  border-radius: 10px;
  transition: 0.3s;
`;

const Dots = ({ instanceRef, currentSlide }) => {
  return (
    <FlexContainer>
      {[
        ...(Array(
          instanceRef.current.track.details?.slides.length
        ).keys() as any),
      ].map((idx) => {
        return (
          <DotStyle
            active={currentSlide === idx}
            onClick={() => {
              instanceRef.current?.moveToIdx(idx);
            }}
            key={idx}
          />
        );
      })}
    </FlexContainer>
  );
};
